import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage Library`}</h2>
    <p>{`Legion design Library are separated in multiple project, the first step is you can make sure was invited to you project file from `}<a parentName="p" {...{
        "href": "/design/getting-started/for-telkom-employee#access-request"
      }}>{`Request Form`}</a>{`, then chek the available library for used from table of list library or the project you assigned. And this is step to select and used the design library before you started to designing.`}</p>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-1.svg",
          "alt": "usage library"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`1. Turn on Library`}</p>{`
    `}<p parentName="div">{`
      Start with `}<strong parentName="p">{`Get a Copy`}</strong>{` match file template from
      `}<a parentName="p" {...{
            "href": "/design/project-setup#file-structure"
          }}>{`File Structure`}</a>{` and drag the file from draft to your folder in project
      file. Then open `}<strong parentName="p">{`team library`}</strong>{` modals from tab `}<strong parentName="p">{`assets`}</strong>{`, serach the
      `}<strong parentName="p">{`[Tribe / Project Name]`}</strong>{` foundation & component spesific platform you need and switch the toogle to
      turn on both of library.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-2.svg",
          "alt": "usage library"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`2. Create Screen`}</p>{`
    `}<p parentName="div">{`
      If you was activated library, then start with create screen / frame for spesific screen size platform or with
      shortcut `}<strong parentName="p">{`Click [A]`}</strong>{`, after that don't forget to used the `}<strong parentName="p">{`grid style`}</strong>{` with match
      screen size from `}<strong parentName="p">{`foundation`}</strong>{` in your frame.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-3.svg",
          "alt": "usage library"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`3. Usage UI Kit Components`}</p>{`
    `}<p parentName="div">{`
      To start usage component from UI Kit, you can select `}<strong parentName="p">{`ressource menu `}</strong>{` and find components or from
      `}<strong parentName="p">{`assets tab `}</strong>{`, then usage your component needed with drag and drop component to your screen, and don't
      miss it to view guideline component usage in `}<a parentName="p" {...{
            "href": "/components/overview"
          }}>{`Components Page`}</a>{`.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-4.svg",
          "alt": "usage library"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`4. Usage UI Kit Foundation`}</p>{`
    `}<p parentName="div">{`
      After used the components, you can continue designing the screen with usage all properties brand from foundation
      (Logo, Colors, Typography, etc). And for consistency you can usage the
      `}<a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=TyaGpGDFczw"
          }}>{`Autolayout`}</a>{` for section content with implement properties
      value from `}<a parentName="p" {...{
            "href": "/foundation/spacer/website"
          }}>{`Spacer`}</a>{`, `}<a parentName="p" {...{
            "href": "/foundation/elevation/website"
          }}>{`Elevation`}</a>{` &
      `}<a parentName="p" {...{
            "href": "/foundation/radius/website"
          }}>{`Radius`}</a>{`.
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-5.svg",
          "alt": "usage library"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`5. Craft more Design Screen`}</p>{`
    `}<p parentName="div">{`
      Finished your design backlog with combine usage `}<strong parentName="p">{`foundation`}</strong>{`, `}<strong parentName="p">{`components`}</strong>{` library
      and `}<a parentName="p" {...{
            "href": "/design/guideline/create-screen#pluggin-recomendation"
          }}>{`Plugin Recomendation`}</a>{`. But if you need
      annotation or GUI component you can activated
      `}<a parentName="p" {...{
            "href": "/design/guideline/create-screen#helper-components-library"
          }}>{`Helpers Components Library`}</a>{`, and for not available
      component you can contact `}<a parentName="p" {...{
            "href": "/design/getting-started/for-telkom-employee#design-library-ecosystem-and-tribe"
          }}>{`
        Design Maintaner Team
      `}</a>{` to create request componens. Or for common flow you can usage `}<a parentName="p" {...{
            "href": "/design/guideline/create-screen#pattern-library"
          }}>{`Pattern Library`}</a>{`
    `}</p>{`
  `}</div>
    </div>
    <br />
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Create new Library:`}</strong>{` If your tribe / squad assigned not available design library, you can create and publish your tribe / squad theme in `}<a parentName="p" {...{
          "href": "/design/getting-started/for-community-external#create-library"
        }}>{`Following Steps`}</a>{` with usage Legion UI Library - Internal. For website platform if you want to create themes for development you can contact core team for assess your foundation before register to legion multi theme.`}</p>
    </InlineNotification>
    <div className="divi" />
    <h2>{`Template & Others Library`}</h2>
    <p>{`Our others Library & Template from legion design library you can activated if you’re was invited to legion figma project. make sure was invited to access file bellow.`}</p>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-6.svg",
          "alt": "usage library"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<h3 parentName="div" {...{
          "className": "text-xl font-bold mb-2 mt-0"
        }}>{`Helper Components Library`}</h3>{`
    `}<p parentName="div">{`
      All collection of all component outside main component in screen (for help design file or flow more informative)
      and components with have mandatory design from operating system or visual component `}<strong parentName="p">{`GUI`}</strong>{` like
      (Browser, Cursor, CAPTCHA, Android or IOS Keyboard, etc).
    `}</p>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/guide-7.svg",
          "alt": "usage library"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<h3 parentName="div" {...{
          "className": "text-xl font-bold mb-2 mt-0"
        }}>{`Pattern Library`}</h3>{`
    `}<p parentName="div">{`
      Collection assets of references for some common flows that are often encountered in various platform, you can
      access it from legion figma library or for sepsific case can access it from image in
      `}<a parentName="p" {...{
            "href": "/pattern/overview"
          }}>{`Pattern Page`}</a>{`, for usage you just copy the pattern flow you needed and paste to your
      design file.
    `}</p>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Pluggin Recomendation`}</h2>
    <p>{`Here are some pluggins that we recommend you use to simplify your workflow, task, accessibility cheking, and design quality`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/929607085343688745/Color-Shades"
        }}><strong parentName="a">{`Color Shades`}</strong></a>{` : Palette editor / generator for easily create beautiful, harmonious and accessible palettes in no time.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/818203235789864127"
        }}><strong parentName="a">{`Batch Styler`}</strong></a>{` : Need to change values of multiple (text and color) styles at once but don’t want to go through the process of changing each text style.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/931280467863251825"
        }}><strong parentName="a">{`Adee Accessibility`}</strong></a>{` : Test color contrast, simulate 8 color blind simulations, test touch target sized to make sure they meet the various device standards.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/732603254453395948"
        }}><strong parentName="a">{`Stark`}</strong></a>{` : Contrast Checker, Focus Order, Alt-Text Annotations, Vision Simulator, and more all in one place.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/798826066406007173"
        }}><strong parentName="a">{`Ditto`}</strong></a>{` : Single source of truth for everyone touching copy, from writers to designers to engineers.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      